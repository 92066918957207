<template>
    <div class="content">

        <h2>Rejestracja</h2>

        <div class="popup" v-if="error">
            <div>
                <div class="close" @click="closePopup();"></div>
                <h6>Komunikat:</h6>
                {{ error }}
            </div>
        </div>

        <div class="register-inputs">

            <div>
                <div class="row">
                    <input type="text" v-model="name" value="" placeholder="Imię i nazwisko" />
                </div>

                <div class="row">
                    <input type="text" v-model="email" value="" placeholder="Adres e-mail" />
                </div>

                <div class="row">
                    <input type="text" style="max-width: 70%;" v-model="pzwl" value="" placeholder="Numer PZWL" />
                </div>
            </div>

            <div>

                <div class="row">
                    <div class="checkbox">
                        <input type="checkbox" id="a1" v-model="accept" value="1" />
                        <label for="a1">
                            Akceptuję <router-link to="/regulamin">Regulamin Konkursu</router-link> i <router-link to="/polityka-prywatnosci">Politykę Prywatności</router-link> *
                        </label>
                    </div>
                </div>

                <div class="row">
                    <div class="checkbox">
                        <input type="checkbox" id="a2" v-model="accept_marketing" value="1" />
                        <label for="a2">
                            Wyrażam <router-link to="/zgoda-marketing">zgodę na marketing drogą elektroniczną</router-link> *
                        </label>
                    </div>
                </div>

                <div class="row">
                    <div class="checkbox">
                        <input type="checkbox" id="a3" v-model="accept_data" value="1" />
                        <label for="a3">
                            Wyrażam <router-link to="/zgoda-konkurs">zgodę na przetwarzanie danych osobowych do celów realizacji Konkursu</router-link> *
                        </label>
                    </div>
                </div>
            </div>

        </div>

        <div class="info">
            * Brak zaznaczenia zgody uniemożliwia zgromadzenie danych i wzięcie udziału w konkursie
        </div>

        <button class="button" @click="register">Zarejestruj się</button>
    </div>
</template>

<script>

    import { model } from "../model.js";

    export default {
        name: 'Register',
        data() {
            return {
                'error': null,
                'name': null,
                'email': null,
                'pzwl': null,
                'accept': 0,
                'accept_marketing': 0,
                'accept_data': 0,
            }
        },

        methods: {

            closePopup: function() {
                this.error = null;
            },

            register: async function()
            {
                var data = {
                    'name': this.name,
                    'email': this.email,
                    'pzwl': this.pzwl,
                    'accept': this.accept,
                    'accept_marketing': this.accept_marketing,
                    'accept_data': this.accept_data
                };

                var response = await model.register(data);

                console.log(response);

                if (response.data.status == 'OK')
                {
                    var token = response.data.results.token;

                    if (token)
                    {
                        localStorage.setItem('token', response.data.results.token);
                        this.$router.push({ name: 'Dashboard' });
                        setTimeout(function() {
                            location.reload();
                        }, 100);

                    }
                }
                else
                {
                    this.error = response.data.message;
                }
            }

        }
    }
</script>
